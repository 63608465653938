<template>
  <div class="home">
      <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>Highlight Covers</h1>
        <div class="text-large">Add some glam to your Instagram!</div>
      </div>
    </div>
    <div class="section bg-gray-4">
      <div class="container">
        <div class="w-layout-grid grid-quarters cards-grid">
          <Photo
            v-for="post in posts"
            :key="post.photo"
            :post="post"
            :showTitle="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import ContentService from "@/services/contentService.js";
import Photo from "@/components/Photo.vue";
export default {
  name: "home",
  components: {
    Navigation,
    Photo
  },
  data() {
    return {
      posts: []
    };
  },
  created()
  {
    ContentService.getContentFromKey("highlight_covers")
      .then(response => {
        console.log(response.data.data.highlight_covers);
        this.posts = response.data.data.highlight_covers;
      })
      .catch(error => {
        console.log("There was an error getting the highlight covers", error);
      });
  }
};
</script>
