<template>
  <div>
    <div class="card no-border">
      <a href="#" class="w-inline-block">
        <img v-bind:src="this.compressedPhoto" width="824" alt class="card-image-head" />
      </a>
      <div class="card-body">
        <a
          v-bind:href="download"
          class="button w-inline-block post-download"
          download
          target="_blank"
        >
          <div>
            Download
            <i class="fas fa-download"></i>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    post: Object,
  },
  computed: {

    download: function() {
      var media = this.post.photo;
      media = media.substring(26);
      var safeTitle = encodeURI("agentNestPhoto");
      safeTitle = safeTitle.replace("?", "");
      safeTitle = safeTitle + ".jpg";
      var url =
        "https://fs.buttercms.com/content=t:attachment,f:%22" +
        safeTitle +
        "%22/" +
        media;
      return url;
    },
    compressedPhoto: function()
    {
      return this.resizePhoto(this.post.photo, 500);

    }
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDD");
      }
    }
  }
};
</script>

<style  scoped>
</style>